<template>
  <div class="wrap bgw">
    <div class="nav">功能反馈</div>
    <div class="content">
      <div>
        <div class="fl">反馈描述</div>
        <div class="fl">
          <textarea maxlength="1000" v-model="content" placeholder="请详细描述您的意见，方便我们改进。"></textarea>
        </div>
      </div>
      <div>
        <button @click="send">提交</button>
      </div>
    </div>
  </div>
</template>
<script>
import { feedback } from "@/request/user";
export default {
  data() {
    return {
      content: " "
    };
  },
  methods: {
    send() {
      if (this.content == " ") {
        this.$message.error("请填写 描述");
      } else {
        feedback({
          token: this.$token,
          content: this.content
        }).then(res => {
          if (res.code == 1) {
            this.$message({
              message: "提交成功",
              type: "success"
            });
          }
          // 切换至状态
          this.$emit("changeState", 0);
        });
      }
    }
  }
};
</script>
<style lang="less" scoped>
.wrap {
  overflow: hidden;
  background: #fff;
}
.nav {
  height: 68px;
  line-height: 68px;
  border-bottom: 1px solid #e6e6e6;
  font-size: 18px;
  padding-left: 20px;
  display: flex;
}
.content {
  height: 600px;
  padding-left: 70px;
  padding-top: 32px;

  div {
    overflow: hidden;
    margin-bottom: 40px;
  }
  .fl:nth-of-type(1) {
    width: 150px;
    font-size: 18px;
  }
  textarea {
    width: 490px;
    height: 244px;
    text-indent: 10px;
    font-size: 16px;
    padding-top: 10px;
    resize: none;
  }
  button {
    width: 130px;
    height: 46px;
    font-size: 18px;
    cursor: pointer;
  }
}
</style>