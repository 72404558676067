// 复诊

<template>
  <div class="pre">
    <p>复诊</p>
    <div class="wrap">
      <div class="evaluate" v-for="(v, i) in furtherList" :key="i">
        <div class="fl">
          <img v-if="v.errorImg" src="@/assets/image/Furtherconsultation_Defaulthead@2x.png" alt />
          <img v-else :src="v.patient_info.avatar" @error="v.errorImg = true" />
        </div>
        <div class="fr">
          <p>
            <span class="name">{{ v.patient_info.realname }}</span>
            <span
              class="sex"
            >{{ v.patient_info.sex == 0 ? '保密' : (v.patient_info.sex == 1 ? '男' : '女') }}</span>
            <span class="age">{{ v.patient_info.age }}</span>
          </p>
          <p>
            电话 :
            <span class="phone">{{ v.patient_info.mobile }}</span>
            <span class="history">
              <span @click="GoRecord(v)"><a>历史诊断记录</a></span>
            </span>
            <span class="time">复诊时间</span>
            <el-date-picker
              v-model="v.date"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <el-time-select format="hh-dd" v-model="v.time" placeholder="选择时间" class="hour"></el-time-select>
            <el-button type="primary" @click="confirmFurtherTime(v)">确认并发送确诊时间</el-button>
          </p>
        </div>
      </div>
      <div class="page" v-if="showPage">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-page="changePage"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// 获取复诊消息
import { getMessageList } from "@/request/personnel";
// 医生设置复诊时间
import { setOrderTime } from "@/request/personnel";
export default {
  data() {
    return {
      furtherList: [],
      total: 0,
      showPage: false
    };
  },
  created() {
    this.getMessagePageList(1)
  },
  methods: {
    getMessagePageList(n) {
      getMessageList({
        token: this.$token,
        type: 3, // 0代表获取系统消息
        page: n
      }).then(res => {
        if(res.code != 1) return;
        this.showPage = res.data.last_page > 1 
        this.total = res.data.total;
        res.data.data.forEach(item => {
          item.errorImg = false;
          item.date = "";
          item.time = "";
        });
        this.furtherList = res.data.data;
        console.log(this.furtherList)
        if(res.data.data.length == 0) {
          this.$message({
            type: "success",
            message: "暂时没有复诊信息"
          })
        }
      });
    },
    confirmFurtherTime(v) {
      if (!v.date && !v.time) {
        this.$message({
          type: "error",
          message: "请输入复诊的具体时间"
        });
        return;
      }
      let date = v.date + " " + v.time;
      setOrderTime({
        token: this.$token,
        order_time: date,
        id: v.message_content.inquiry_order_id
      }).then(res => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "发送成功"
          });
        } else if(res.code == 0) {
           this.$message.error('你已经设置过复诊时间了');
        } 
      });
    },
    changePage(n) {
      this.getMessagePageList(n)
    },
    GoRecord(v) {
      this.$emit("changeState", 6);
    }
  }
};
</script>

<style lang="less" scoped>
.pre {
  margin-bottom: 120px;
  width: 1200px;
  margin: 0 auto;
  font-size: 16px;
  background: #fff;
}
.pre > p {
  font-size: 20px;
  color: #000;
  height: 70px;
  border: 1px solid #ccc;
  border-bottom: 0px;
  line-height: 70px;
  padding-left: 30px;
}
.evaluate:after {
  content: "";
  display: block;
  clear: both;
}
.wrap {
  border: 1px solid #ccc;
  min-height: 600px;
  padding-bottom: 60px;
}
.evaluate {
  font-size: 16px;
  margin-top: 50px;
  padding-left: 30px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 30px;
}
.evaluate .fr {
  float: left;
  margin-top: 10px;
}
.evaluate .fr p {
  color: #555;
  font-size: 16px;
  height: 30px;
  margin-left: 30px;
}
.evaluate .fr p:nth-child(1) {
  span {
    margin-right: 10px;
  }
}
.evaluate .fl img {
  width: 80px;
  height: 80px;
}
.history,
.time {
  margin-left: 30px;
}
.history a {
  color: #4eeec8;
}
.el-date-editor.el-input.hour {
  margin-left: -60px;
  cursor: pointer;
}
.el-button {
  background: #4eeec8;
  border: none;
}
.page {
  text-align: center;
  margin: 30px 0px;
}
</style>