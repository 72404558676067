// 我的评价

<template>
  <div class="pre">
    <p>我的评价</p>
    <div class="wrap">
      <div class="evaluate" v-for="(v, i) in commentList" :key="i">
        <div class="fl">
          <img
            v-if="v.isDefaultImg || v.user_info.avatar == null || v.user_info.avatar == ''"
            src="@/assets/image/Furtherconsultation_Defaulthead@2x.png"
            alt
          />
          <img v-else :src="v.user_info.avatar" @error="v.isDefaultImg = true" />
        </div>
        <div class="fr">
          <p>{{ v.user_info.user_nickname }}</p>
          <p>评价内容 : {{ v.content }}</p>
        </div>
      </div>
      <div class="page" v-if="showPage">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="currentChangePage"
          :page-size="15"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// 医生获取自己的评论
import { getCommentList } from "@/request/personnel";
export default {
  data() {
    return {
      commentList: [],
      total: 0,
      showPage: false // 是否显示分页
    };
  },
  created() {
    this.start();
  },
  methods: {
    start() {
      this.getCommentPageList(1)
    },
    getCommentPageList(n) {
      getCommentList({
        token: this.$token,
        doctor_uid: this.$userinfo.id,
        page: n
      }).then(res => {
        if (res.code == 1) {
          this.showPage = res.data.last_page > 1;
          this.total = res.data.total;
          res.data.data.forEach(item => item.isDefaultImg = false)
          this.commentList = res.data.data;
        }
      });
    },
    currentChangePage(n) {
      // 当前页改变触发
      this.getCommentPageList(n);
    }
  }
};
</script>

<style scoped>
.pre {
  width: 1200px;
  margin: 0 auto;
  margin-top: 10px;
  font-size: 16px;
  background: #fff;
}
.pre > p {
  font-size: 20px;
  color: #000;
  height: 70px;
  border: 1px solid #ccc;
  border-bottom: 0px;
  line-height: 70px;
  padding-left: 15px;
}
.evaluate:after {
  content: "";
  display: block;
  clear: both;
}
.wrap {
  border: 1px solid #ccc;
  padding-bottom: 40px;
  min-height: 600px;
}
.evaluate {
  font-size: 15px;
  padding: 40px 0px;
  padding-left: 30px;
  border-bottom: 1px solid #ccc;
}
.evaluate img {
  width: 80px;
  height: 80px;
}
.evaluate .fr {
  float: left;
  line-height: 40px;
}
.evaluate .fr p {
  color: #555;
  font-size: 15px;
  height: 30px;
  margin-left: 30px;
  width: 800px;
  line-height: 30px;
}
.page {
  text-align: center;
  margin: 30px 0px;
}
</style>