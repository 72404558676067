<template>
	<div class="pre">
		<template v-if="state === 1">
			<p>全部病例</p>
			<div class="wrap">
				<div class="evaluate" v-for="(item, i) in inquiryList" :key="i">
					<!-- 确保处方单类型是 非图片处方 -->
					<div>
						<div class="fl">
							<img v-if="!item.errorImg" :src="item.patient_info.avatar" @error="item.errorImg = true" />
							<img v-else src="@/assets/image/Furtherconsultation_Defaulthead@2x.png" />
						</div>
						<div class="fr">
							<p>
								用户姓名
								<span class="name">{{ item.patient_info.realname ? item.patient_info.realname : "无" }}</span>
								<span class="sex">{{ item.patient_info.sex === 0 ? "保密" : (item.patient_info.sex === 1 ? "男" : "女")}}</span>
								<span class="age">{{ item.patient_info.age ? item.patient_info.age : "无"}}</span>
								电话 :
								<span class="phone">{{ item.patient_info.mobile ? item.patient_info.mobile : "无" }}</span>
							</p>
							<p>
								科别 :
								<span>{{ item.patient_info.category_name ? item.patient_info.category_name : "无" }}</span>
							</p>
							<p>
								诊断结果 :
								<span>{{ item.patient_info.diagnosis ? item.patient_info.diagnosis : "无" }}</span>
							</p>
							<p>
								问诊时间:
								<span>{{item.start_time |formatTime}}</span>
							</p>
							<!--              <p class="details">
                <span @click="changeState(2, item)">查看详情</span>
              </p> -->
						</div>
						<div class="btn">
							<el-button type="primary" plain @click="changeState(2, item.id)">查看病例</el-button>
						</div>
					</div>
				</div>
				<div class="page" v-if="showPage">
					<el-pagination background layout="prev, pager, next" :total="total" @current-change="currentChangePage" :page-size="15"></el-pagination>
				</div>
			</div>
		</template>
		<template v-if="state === 2">
			<div class="case-box">
				<div class="case-info">
					<div class="case-title">
						<h4>浙江孚诺医药股份有限公司互联网医院</h4>
						<p>就诊号：{{userinfor.inquiry_id}}</p>
					</div>
					<div class="case-top">
						<div class="case-top-info">
							<div class="case-left">
								<p>姓名：<span>{{userinfor.inquiry_info.realname}}</span></p>
								<p>年龄：<span>{{userinfor.inquiry_info.age}}</span></p>
								<p>性别：<span>{{userinfor.inquiry_info.sex===0?"保密":(userinfor.inquiry_info.sex===1)?"男":"女"}}</span></p>
								<p>体重：<span>{{userinfor.inquiry_info.weight==""?"无":userinfor.inquiry_info.weight+"kg"}}</span></p>
							</div>
							<div class="case-right">
								<p>身高：<span>{{userinfor.inquiry_info.height==""?"无":userinfor.inquiry_info.height+"cm"}}</span></p>
								<p>过敏史：<span>{{userinfor.inquiry_info.allergy}}</span></p>
								<p>遗传史：<span>{{userinfor.inquiry_info.genetic_disease}}</span></p>
								<p>病情状态：<span>{{condition}}</span></p>
								<!-- <p>家族史：<span>{{userinfor.patient_info.allergy}}</span></p> -->
							</div>
						</div>
						<div class="case-img" v-if="userinfor.inquiry_info.report_url_arr.length>0">
							<span>检查报告</span>
							<div v-for="item in userinfor.inquiry_info.report_url_arr">
								<img :src="item" alt="">
							</div>
						</div>
					</div>
					<div class="case-content">
						<div class="case-left">
							<p>开始时间：<span>{{userinfor.start_time | formatTime}}</span></p>
							<p>结束时间：<span>{{userinfor.end_time | formatTime }}</span></p>
							<p>诊疗科目：<span>{{userinfor.category_name}}</span></p>
						</div>
						<div class="case-right">
							<p>交流方式：<span>{{userinfor.chat_type==0?"图文问诊":"视频问诊"}}</span></p>
							<p>诊疗医师：<span>{{userinfor.doctor_realname}}</span></p>
						</div>

					</div>
					<div class="case-content">
						<div>
							<p>疾病名称:<span>{{ userinfor.inquiry_info.diagnosis}}</span></p>
							<p>诊断意见:<span> {{userinfor.inquiry_info.reportTxt}}</span></p>
						</div>
					</div>
					<div class="content">
						<div class="type">R</div>
						<div class="name" v-for="(item, i) in detailPre.goods_list" :key="i">
							<el-row>
								<el-col :span="8">{{ item.goods_name }}</el-col>
								<el-col :span="8">{{item.spec}}</el-col>
								<el-col :span="8">x{{item.num}} {{item.unit}}</el-col>
								<el-col :span="6">用法</el-col>
								<el-col :span="6">{{ item.drugRoute_name}}</el-col>
								<el-col :span="6">每次{{item.drugDosage}}{{item.drugUseUnit_name}}</el-col>
								<el-col :span="6">服用{{item.drugDay}}天</el-col>
							</el-row>
						</div>
					</div>
					<div class="case-sign">
						<p>医生签字:</p>
						<img :src="doctorSign.audit_sign">
					</div>
				</div>
			</div>
		</template>
		<template v-if="state === 3">
			<div class="disponseInfo">
				<div class="bill">
					<div class="date">{{ detailPre.add_time}}</div>
					<div class="invald">本处方单3个月内有效</div>
					<h3>处方笺</h3>
					<el-row>
						<el-col :span="12">
							科别:
							<span>{{ detailPre.inquiry_info.category_name }}</span>
						</el-col>
						<el-col :span="12">
							处方号:
							<span>{{ detailPre.p_code }}</span>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="8">
							姓名:
							<span>{{ detailPre.inquiry_info.realname }}</span>
						</el-col>
						<el-col :span="8">
							年龄:
							<span>{{ detailPre.inquiry_info.age }}</span>
						</el-col>
						<el-col :span="8">
							性别:
							<span>{{ detailPre.inquiry_info.sex === 0 ? "保密" : (detailPre.inquiry_info.sex === 1 ? "男" : "女" )}}</span>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							过敏史:
							<span>{{ detailPre.inquiry_info.allergy }}</span>
						</el-col>
						<el-col :span="12">
							遗传史:
							<span>{{ detailPre.inquiry_info.allergy.genetic_disease}}</span>
						</el-col>
					</el-row>
					<el-row>
						临床诊断:
						<span>{{ detailPre.inquiry_info.allergy.diagnosis }}</span>
					</el-row>
					<div class="content">
						<div class="type">R</div>
						<div class="name" v-for="(item, i) in detailPre.goods_list" :key="i">
							<el-row>
								<el-col :span="8">{{ item.goods_name }}</el-col>
								<el-col :span="8">10g:0.5mg</el-col>
								<el-col :span="8">x{{ item.num }}{{ item.unit }}</el-col>
								<el-col :span="8">用法 内服</el-col>
								<el-col :span="8">{{ item.yongfa }} {{item.yongliang}}</el-col>
								<el-col :span="8">每次0.1g</el-col>
							</el-row>
						</div>
					</div>

					<div class="graph">
						<el-row>
							<el-col :span="12">
								<p>医生签字:</p>
								<img :src="doctorSign && doctorSign.audit_sign" />
							</el-col>
							<el-col :span="12">
								<p>药师签字:</p>
								<img :src="pharmSign && pharmSign.audit_sign" />
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
	import {
		getList
	} from "@/request/personnel"; // 获取自己的问诊列表
	import {
		getInfo
	} from "@/request/personnel"; // 处方单详情
	import {
		getcaseInfo
	} from "@/request/personnel"; // 处方单详情
	import {
		getBedInfor
	} from "@/request/personnel";
	export default {
		data() {
			return {
				time: "",
				state: 1,
				inquiryList: [],
				serviceInfo: {}, // 当前服务对象
				detailPre: {}, // 当前处方详情对象
				doctorSign: {}, // 医生或专家的签名对象
				pharmSign: {}, // 药师的签名对象
				total: 0,
				showPage: false,
				userinfor: {},
				condition:"",
				conditionlist:[],
			};
		},
		filters: {
			formatTime: function(value) {
				let time;
				var times = new Date(value * 1000);
				let y = times.getFullYear();
				let M = times.getMonth() + 1;
				let d = times.getDate();
				let h = times.getHours();
				let m = times.getMinutes();
				let s = times.getSeconds();
				M = M >= 10 ? M : ('0' + M);
				d = d >= 10 ? d : ('0' + d);
				h = h >= 10 ? h : ('0' + h);
				m = m >= 10 ? m : ('0' + m);
				s = s >= 10 ? s : ('0' + s);
				time = y + "-" + M + "-" + d + "  " + h + ":" + m + ":" + s;
				return time;
			}
		},
		created() {
			getBedInfor({
				table:"HOSPITALIZATIONSTATE"
			}).then(res=>{
				this.conditionlist=res.data;
			})
			this.getPageList(1);
		},
		methods: {
			clearCase() {
				for(let i in this.conditionlist){
					if(this.conditionlist[i].code==this.userinfor.inquiry_info.condition){
						this.condition=this.conditionlist[i].name;
					}
				}
			},
			getPageList(n) {
				getList({
					token: this.$token,
					page: n
				}).then(res => {
					console.log(res)
					res.data.data.forEach(item => (item.errorImg = false));
					this.showPage = res.data.last_page > 1;
					this.total = res.data.total;
					this.inquiryList = res.data.data;

				});
			},
			changeState(num, id) {

				getcaseInfo({
					token: this.$token,
					inquiry_doctor_id: id
				}).then(res => {
					if (res.code == 1) {
						this.userinfor = res.data;
						getInfo({
							token: this.$token,
							inquiry_id: this.userinfor.inquiry_id
						}).then(res => {
							if (res.code == 1) {
								this.detailPre = res.data;
								console.log(this.detailPre);
								this.detailPre.sign_list.forEach(item => {
									// console.log(item.cert_type)
									if (item.cert_type == 1 || item.cert_type == 2) {
										this.doctorSign = item
									} else if (item.cert_type == 3) {
										this.pharmSign = item;
									}
								})
								this.clearCase();
							} else {
								this.$message.error(res.msg);
							}
						});
					} else {
						this.$message.error(res.msg);
					}
					this.state = num;
					console.log(this.userinfor);
				});




			},
			currentChangePage(n) {
				this.getPageList(n);
			},
			getPreInfo(data) {
				getInfo({
					token: this.$token,
					p_code: data.p_code
				}).then(res => {
					console.log(res)
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	.case-box {
		width: 800px;
		min-width: 800px;
		margin: 0 auto;
		background: #fff;
		padding: 30px 40px;
	}

	.case-box .content {
		padding:20px 40px;
		border-bottom: 2px solid #a8a8a8;
	}

	.case-sign {
		padding: 20px 40px;

		p {
			margin-bottom: 10px;
		}
	}

	.case-sign img {
		width: 150px;
		height: 150px;
	}

	.case-box .case-info {
		background: #f5f5f5;
		padding: 40px 100px;
	}

	.case-box .case-title {
		text-align: center;

		h4 {
			margin-bottom: 10px;
		}
	}

	.case-top-info,
	.case-content {
		width: 100%;
		padding: 0 40px;
		display: flex;
		justify-content: space-between;

		p {
			margin: 10px 0;

			span {
				color: #8f8f8f;
			}
		}
	}

	.case-top,
	.case-content {
		padding: 20px 0px;
		border-bottom: 2px solid #a8a8a8;
	}

	.case-top .case-img {
		padding: 0 40px;

		span {
			display: block;
			margin-bottom: 10px;
		}

		img {
			display: block;
			width: 150px;
			height: 150px;
		}
	}

	.case-content {
		padding: 20px 40px;
	}

	.evaluate .btn /deep/ {
		position: absolute;
		top: 50%;
		right: 40px;
		transform: translateY(-90%);
		
		.el-button--primary {
			margin-right: 20px;
			color:#fff;
		}
	}

	.pre {
		width: 1200px;
		margin: 0 auto;
		min-height: 830px;
		margin-top: 10px;
		font-size: 16px;
	}

	.wrap {
		background: #fff;
	}

	.pre>p,
	.details-wrap>p {
		background: #fff;
		font-size: 20px;
		color: #000;
		height: 70px;
		border: 1px solid #ccc;
		border-bottom: 0px;
		line-height: 70px;
		padding-left: 15px;
	}

	.details-wrap>p {
		color: #4eeec8;
	}

	.evaluate:after {
		content: "";
		display: block;
		clear: both;
	}

	.wrap {
		min-height: 690px;
		padding-bottom: 40px;
		border: 1px solid #ccc;
	}

	.page {
		text-align: center;
		margin: 30px 0px 0px 0px;
	}

	.evaluate {
		position: relative;
		font-size: 14px;
		margin-top: 40px;
		padding-left: 30px;
		padding-bottom: 20px;
		border-bottom: 1px solid #ccc;
	}

	.evaluate .fr {
		float: left;

		span {
			margin-right: 8px;
		}
	}

	.evaluate .fr p {
		color: #555;
		font-size: 12px;
		height: 30px;
		margin-left: 30px;
	}

	.evaluate .fl img {
		width: 80px;
		height: 80px;
	}

	.details span {
		color: #4eeec8;
		cursor: pointer;
	}

	.details-wrap {
		.wrap {
			section {
				position: relative;
				overflow: hidden;
				border-bottom: 1px solid #ccc;

				button {
					padding: 6px 20px;
					background: #fff;
					color: #4eeec8;
					border: 1px solid #4eeec8;
					position: absolute;
					right: 60px;
					bottom: 20px;
					cursor: pointer;
				}
			}

			.title {
				padding-top: 30px;

				img {
					margin-left: 30px;
					width: 100px;
					height: 100px;
				}

				dl {
					display: inline-block;
					margin-left: 30px;

					dd {
						margin-bottom: 14px;

						span {
							margin-right: 6px;
						}
					}

					dd:nth-child(1) {
						span {
							margin-right: 20px;
						}
					}
				}

				width: 800px;
				height: 160px;

				.name {
					color: #4eeec8;
				}
			}

			.content {
				padding-top: 15px;
				height: 45px;
				line-height: 45px;
				font-size: 18px;

				span {
					display: inline-block;
					width: 120px;
				}

				span:nth-child(1) {
					margin-left: 50px;
					color: #313131;
				}

				span:nth-child(2) {
					color: #a8a8a8;
					width: 900px;
				}
			}
		}
	}

	.disponseInfo {
		width: 1200px;
		color: #333;
		margin: 0 auto;
		border: 1px solid #ccc;
		padding: 100px 0px;
		background: #fff;
	}

	.disponseInfo>div {
		padding: 30px;
	}

	// 单据信息
	.disponseInfo .bill {
		box-sizing: border-box;
		background: #f5f5f5;
		margin-left: 200px;
		box-shadow: 5px 5px 5px #ccc;
		width: 600px;
		min-height: 744px;
		font-size: 16px;
		border: 1px solid #ccc;
		padding: 20px;

		.date {
			margin-bottom: 5px;
		}

		h3 {
			font-size: 28px;
			font-weight: 500;
			text-align: center;
			margin: 10px 0px;
		}

		>.el-row {
			border-bottom: 1px solid #424242;
			line-height: 40px;
		}

		.content {
			min-height: 100px;
			padding: 10px 0px 15px 0px;

			.el-row {
				border: none;
				padding: 3px 0;

				.el-col {
					margin: 3px 0;
				}

				.el-col:nth-child(2) {
					text-align: center;
				}

				.el-col:nth-child(4) {
					text-indent: 2em;
				}

				.el-col:nth-child(5) {
					text-align: center;
				}

				.el-col:nth-child(3) {
					text-align: right;
				}

				.el-col:nth-child(6) {
					text-align: right;
				}

				.el-col {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}

			.type {
				font-size: 22px;
				font-weight: 700;
			}

			.name {
				margin: 3px 0;
			}
		}

		.graph {
			border-top: 1px solid #424242;
			height: 150px;

			.el-row {
				margin-top: 12px;

				img {
					width: 100%;
				}
			}
		}
	}

	.disponseInfo .autograph {
		height: 100px;
		line-height: 100px;
		text-align: center;
		font-size: 13px;
		color: #999;
		margin-left: -25px;
		cursor: pointer;
	}

	.disponseInfo div.wrap_autograph {
		width: 800px;
		margin-top: 15px;

		h3 {
			margin-bottom: 15px;
			font-weight: 500;
			margin-left: 20px;
		}

		textarea {
			margin-bottom: 10px;
			font-size: 18px;
			padding: 8px;
		}
	}

	.disponseInfo div.wrap_autograph div {
		text-align: center;
	}

	.el-button.el-button--primary {
		background-color: #38eec1;
		border: #38eec1;
	}
</style>
