<template>
	<div class="service">
		<p>我的药方</p>
		<div class="explain" v-for="(item, i) in prelist" :key="i">
			<p>
				<span class="time">{{ item.add_time }}</span>
				<span class="name">开具给{{ item.inquiry_name }}</span>
			</p>
			<div class="wrapper" v-for="(item,j) in item.goods_list" :key="j">
				<el-row>
					<el-col :span="8">{{ item.goods_name }}</el-col>
					<el-col :span="8">{{item.spec}}</el-col>
					<el-col :span="8">x{{item.num}} {{item.unit}}</el-col>
				</el-row>
				<el-row>
					<el-col :span="4">用法</el-col>
					<el-col :span="5">{{ item.drugRoute_name}}</el-col>
					<el-col :span="5">每次{{item.drugDosage}}{{item.drugUseUnit_name}}</el-col>
					<el-col :span="5">{{item.drugFrequency_name}}</el-col>
					<el-col :span="5">服用{{item.drugDay}}天</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
	// 获取自己添加的处方单
	import {
		getPrescriptionList
	} from "@/request/personnel";

	export default {
		data() {
			return {
				prelist: []
			};
		},
		created() {
			getPrescriptionList({
				token: this.$token
			}).then(res => {
				if (res.code == 1) {
					this.prelist = res.data.data;
					this.prelist = this.prelist.filter(item => item.goods_list.length != 0)
				}
			});
		}
	};
</script>

<style scoped>
	.service {
		width: 1200px;
		margin: 0 auto;
		min-height: 967px;
		margin-top: 10px;
		font-size: 16px;
		background: #fff;
		border: 1px solid #ccc;
	}

	.service>p {
		font-size: 18px;
		color: #4eeec8;
		height: 70px;
		line-height: 70px;
		border-bottom: 1px solid #ccc;
		padding-left: 20px;
	}

	.explain {
		width: 1132px;
		margin: 15px auto 15px;
		background: #fcfcfc;
	}

	.explain>p {
		height: 66px;
		line-height: 66px;
		color: #000000;
		background: #f6f6f6;
		padding-left: 30px;
	}

	.explain span:nth-child(2) {
		margin-left: 25px;
	}

	.wrapper {
		width: 700px;
		padding: 20px 0px;
		padding-left: 20px;
		border-bottom: 1px solid #fcfcfc;
	}

	.wrapper .title,
	.wrapper .content {
		display: flex;
		margin-bottom: 10px;
	}

	.wrapper .title div,
	.wrapper .content div {
		width: 200px;
	}

	.wrapper .title .good_name {
		width: 280px;
		padding-right: 20px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}

	.wrapper .content .fangshi {
		width: 280px;
		padding-right: 20px;
	}
</style>
